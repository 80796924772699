export const projects = [
  {
    title: "Broker assicurazioni",
    subtitle: "AdminLTE e PHP",
    description:
      "Per gestire le polizze da diverse compagnie è stato realizzato un gestionale che permetta di tenere traccia di tutto tramite un unico portale, " +
      "in questo modo si hanno sempre a portata scadenze, contatti e statistiche generali.",
    image: "./project-1.gif",
    link: "#",
  },
  {
    title: "Spurghi",
    subtitle: "AdminLTE e PHP",
    description:
      "Per gestire le statistiche e velocizzare i preventivi di una società di spurghi multi sede è stato realizzato un software che tra le varie funzionalità " +
      "permette di calcolare il percorso e la distanza del cliente dalle sedi.",
    image: "./project-2.gif",
    link: "#",
  }
];

export const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Jesse Hicks",
    company: "Zoozle",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "Ruben Alvarez",
    company: "Dooble.io",
  },
];

export const skills = [
  "PHP",
  "JavaScript",
  "HTML",
  "CSS",
  "Configurazione hosting",
  "GNU / Linux"
];
