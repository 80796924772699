import React from "react";
import axios from "axios";

const API_PATH = 'https://mploop.it/api/sendEmail.php';


export default function Contact() {

  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  let [mailSent, setMailSent] = React.useState(false);
  let [sentErr, setSentErr] = React.useState("");
  let [error, setError] = React.useState(null);

  function handleFormSubmit( e ) {
    e.preventDefault();

    if(!e.target.surname.value){
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: e.target
      })
        .then(result => {
            setMailSent(result)
            setSentErr(result.data.sentErr)
            e.target.reset()
            setName("")
            setEmail("")
            setMessage("")
            setError(null)
        })
        .catch(error => setError(error.message) );
    }
  }
  

  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            title="map"
            className="absolute inset-0"
            style={{ filter: "opacity(0.7)", border: 0, margin: 0 }}
            src="https://www.google.com/maps/embed/v1/place?q=Torino,+TO,+Italia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          />
        </div>
        <form
          name="test"
          onSubmit={handleFormSubmit}
          className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Ingaggiami
          </h2>
          <p className="leading-relaxed mb-5">
            Hai bisogno di ottimizzare un processo o non sai se &egrave; possibile farlo
            con un software personalizzato?
            <br/>Scrivimi e sar&ograve; felice di rispondere alle tue domande.
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm">
              Nome
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full bg-secondary-500 rounded border border-secondary-600 focus:border-primary-500 focus:ring-2 focus:ring-primary-600 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="hidden"
              id="surname"
              name="surname"
              className="w-full bg-secondary-500 rounded border border-secondary-600 focus:border-primary-500 focus:ring-2 focus:ring-primary-600 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setSurname(e.target.value)}
              required
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-secondary-500 rounded border border-secondary-600 focus:border-primary-500 focus:ring-2 focus:ring-primary-600 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm">
              Messaggio
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-secondary-500 rounded border border-secondary-600 focus:border-primary-500 focus:ring-2 focus:ring-primary-600 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-primary-400 border-0 py-2 px-6 focus:outline-none hover:bg-primary-500 rounded text-lg">
            Invia
          </button>
          <div className="relative mt-4">
            {mailSent &&
              <div className="bg-primary-200 border-0 py-2 px-6 focus:outline-none rounded text-lg">Grazie per aver scritto, verrai contattato nel minor tempo possibile.</div>
            }
            {sentErr}
            {error &&
              <div className="bg-red-400 border-0 py-2 px-6 focus:outline-none rounded text-lg">{error}</div>
            }
          </div>
        </form>
      </div>
    </section>
  );
}
